/* eslint-disable no-nested-ternary */
import {
  Alert,
  Box, CircularProgress, Container, FormLabel, Link,
  Snackbar, TextField, Theme, Typography, useTheme,
} from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { t } from 'i18next';
import { useLogo } from '../config/useLogo';
import { useSolutionOrg } from '../config/useSolutionOrg';
import CallToActionButton from './CallToActionButton';
import { useAppDispatch } from '../store/hooks';
import { submitCustomerSupportForm } from '../store/thunks/authenticationThunk';
import { emailRegex } from './constants';
import NavHeader from './NavHeader';

interface IFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  description: string;
}

const inputStyle = (theme: Theme) => ({
  mt: theme.spacing(0.5),
  mb: theme.spacing(3),
  borderRadius: theme.spacing(1),
  '& .MuiInputBase-root': {
    borderRadius: theme.spacing(1),
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: theme.palette.secondary.main,
    },
  },
} as const);

const labelStyle = (theme: Theme) => ({
  color: theme.palette.grey56.main,
} as const);

export default function CustomerSupport() {
  const theme = useTheme();
  const { secondaryOne: Logo } = useLogo();
  const { envSolutionOrgTitle } = useSolutionOrg();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const solutionOrg = searchParams.get('solutionOrg');
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    reset,
  } = useForm<IFormInputs>();

  const openLoginForm = () => {
    if (solutionOrg) {
      navigate(`/login?solutionOrg=${solutionOrg}`);
    } else {
      navigate('/login');
    }
  };

  const handleClose = () => {
    setOpenErrorSnackbar(false);
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (!formSubmitted) {
      setIsLoading(true);
      if (data) {
        dispatch(submitCustomerSupportForm({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          description: data.description,
          solutionOrg,
        }))
          .then(unwrapResult)
          .then(() => {
            setOpenErrorSnackbar(false);
            setIsLoading(false);
            setFormSubmitted(true);
          })
          .catch((err: unknown) => {
            setOpenErrorSnackbar(true);
            setIsLoading(false);
          });
      }
    } else {
      // show support form to create new ticket
      setFormSubmitted(false);
      reset();
    }
  };

  return (
    <>
      <NavHeader />
      <Container
        maxWidth='sm'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: theme.spacing(112),
          pr: theme.spacing(3),
          pl: theme.spacing(3),
        }}
      >
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {t('error.generalMessage')}
          </Alert>
        </Snackbar>
        <>
          <Box sx={{ textAlign: 'center' }}>
            {Logo && <Logo />}
          </Box>
          <Box
            sx={{
              background: theme.palette.common.white,
              borderRadius: theme.spacing(2.25),
              paddingLeft: theme.spacing(3.5),
              paddingRight: theme.spacing(3.5),
              paddingTop: theme.spacing(3),
              marginTop: theme.spacing(3),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(1),
                margin: '0 auto',
              }}
            >
              <Typography variant="h4" sx={{
                mb: theme.spacing(4),
              }}>
                Contact Support
              </Typography>

              <form onSubmit={handleSubmit(onSubmit)}>
                {!formSubmitted ?
                  <Box>
                    <Box
                      sx={{
                        [theme.breakpoints.up('sm')]: {
                          display: 'flex',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          [theme.breakpoints.up('sm')]: {
                            width: '50%',
                            mr: theme.spacing(2),
                          },
                        }}
                      >
                        <FormLabel sx={labelStyle}>First Name*</FormLabel>
                        <TextField
                          placeholder='First Name'
                          {...register('firstName', { required: true })}
                          fullWidth
                          sx={inputStyle}
                        />
                      </Box>
                      <Box
                        sx={{
                          [theme.breakpoints.up('sm')]: {
                            width: '50%',
                          },
                        }}
                      >
                        <FormLabel sx={labelStyle}>Last Name*</FormLabel>
                        <TextField
                          placeholder='Last Name'
                          {...register('lastName', { required: true })}
                          margin="normal"
                          fullWidth
                          sx={inputStyle}
                        />
                      </Box>
                    </Box>
                    <FormLabel sx={labelStyle}>Email Address*</FormLabel>
                    <TextField
                      placeholder='Email Address'
                      {...register('email', {
                        required: true,
                        pattern:
                          emailRegex,
                      })}
                      margin="normal"
                      fullWidth
                      sx={inputStyle}
                    />
                    <FormLabel sx={labelStyle}>Description*</FormLabel>
                    <TextField
                      placeholder='Please describe the issue you are facing'
                      {...register('description', { required: true })}
                      margin="normal"
                      fullWidth
                      multiline
                      rows={4}
                      sx={inputStyle}
                    />
                  </Box> : <Alert icon={<CheckCircleIcon />}
                    sx={{ bgcolor: theme.palette.common.white }}
                  >
                    {t('success.supportTicketSubmitted')}
                  </Alert>
                }
                <CallToActionButton
                  text={isLoading ?
                    <CircularProgress color="inherit" size={24} /> :
                    (formSubmitted ? `${t('button.createNewTicket')}` :
                      `${t('button.submitSupportTicket')}`)}
                  type={'submit'}
                  sx={{
                    background: theme.palette.secondary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                    '&:disabled': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                    position: 'static',
                    width: '100%',
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(4),
                    paddingTop: theme.spacing(0.5),
                    paddingBottom: theme.spacing(0.5),
                    borderRadius: theme.spacing(1),
                    fontWeight: '600',
                    fontSize: theme.spacing(2.35),
                    [theme.breakpoints.up('sm')]: {
                      width: '100%',
                      marginLeft: '0px',
                    },
                  }}
                  disable={!formState.isValid || isLoading}
                />
              </form>
            </Box>
          </Box>
          <Box
            sx={{
              mt: theme.spacing(3),
              textAlign: 'center',
            }}
          >
            <Typography variant='body7'>{t('supportForm.or')} &nbsp;
              <Link onClick={() => openLoginForm()}
                sx={{
                  cursor: 'pointer',
                }}
                variant='h6' color={theme.palette.secondary.main}>
                {`${t('supportForm.backToLogin')} ${envSolutionOrgTitle}`}</Link>
            </Typography>
          </Box>
        </>
      </Container>
    </>
  );
}
