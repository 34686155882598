import { Box, Container, useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { useEffect, useRef, useState } from 'react';
import { DashboardNavElement } from './DashboardNavElement';
import { useLogo } from '../../config/useLogo';

interface DashboardDesktopLayoutSFProps {
  children: JSX.Element;
  navElems: {
    name: string;
    path: string;
    Outline: typeof SvgIcon;
    Filled: typeof SvgIcon;
    isCurrent?: boolean;
  }[];
  rightColumnElements?: JSX.Element[];
  noFullWidth?: boolean;
}

const containerStyle = () => ({
  marginTop: '32px',
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '1400px',
} as const);

export function DashboardDesktopLayout(
  {
    children, navElems, rightColumnElements = [],
    noFullWidth,
  }: DashboardDesktopLayoutSFProps,
) {
  const trackableRef = useRef<HTMLElement | null>(null);
  const [paddingLeft, setpaddingLeft] = useState('20px');
  const theme = useTheme();
  const { secondaryOne: NavLogo } = useLogo();

  useEffect(() => {
    const trackPosition = () => {
      if (trackableRef.current) {
        const rect = trackableRef.current.getBoundingClientRect();
        setpaddingLeft(`${rect.left}px`);
      }
    };

    // Track position on mount
    trackPosition();

    // Track position on resize
    window.addEventListener('resize', trackPosition);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('resize', trackPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MenuBar = () => (<Box
    sx={{
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.12),0px 0px 4px 0px rgba(0, 0, 0, 0.14)',
      width: '100%',
      height: '79px',
      paddingLeft: `${paddingLeft}`,
      position: 'sticky',
      top: 0,
      zIndex: 999,
    }}
  >
    {NavLogo && <NavLogo
      height={43}
      width={204}
      style={{
        marginTop: theme.spacing(2.25),
      }}
    />}
  </Box>);

  return (
    <>
      <MenuBar />
      <Container
        maxWidth={false}
        sx={containerStyle}
      >
        <Box
          ref={trackableRef}
          sx={{
            backgroundColor: theme.palette.common.white,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(3),
            width: '100%',
            maxWidth: theme.spacing(40),
            borderRadius: '13px',
            height: 'fit-content',
            position: 'sticky',
            top: theme.spacing(12.5),
          }}
        >
          {
            navElems.map((elem, index) => (
              <DashboardNavElement
                key={index}
                sx={{
                  marginBottom: theme.spacing(4.5),
                }}
                name={elem.name}
                outlineIcon={elem.Outline}
                filledIcon={elem.Filled}
                path={elem.path}
                isSelected={elem.isCurrent ?? false}
              />
            ))
          }
        </Box>
        <Container sx={{
          marginLeft: '0px',
          marginRight: '0px',
        }}
          maxWidth={(rightColumnElements.length > 0 || noFullWidth) ? 'sm' : 'md'}
        >
          {children}
        </Container>
        {(rightColumnElements?.length > 0 || noFullWidth) && <Box sx={{
          width: '100%',
          maxWidth: '350px',
          top: theme.spacing(12.5),
        }}>
          {
            rightColumnElements.map((element, index) => (
              <Box key={index} sx={{ marginBottom: theme.spacing(4) }}>
                {element}
              </Box>))
          }
        </Box>}
      </Container>
    </>
  );
}
