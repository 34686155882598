export const HOME_AUTH_LOGIN_ROUTE = '/login';
export const ONBOARDING_BASE_ROUTE = '/onboarding';
export const ONBOARDING_AUTH_LOGIN_ROUTE = `${ONBOARDING_BASE_ROUTE}/login`;
export const JOIST_ONBOARDING_AUTH_LOGIN_ROUTE = `/in-app${ONBOARDING_BASE_ROUTE}/login`;
export const JOIST_V2_ONBOARDING_AUTH_LOGIN_ROUTE = `/in-app/v2${ONBOARDING_BASE_ROUTE}/login`;
export const HOME_DEPOT_SETUP_OR_SKIP_ROUTE = '/onboarding/setuphomedepot';
export const HOME_DEPOT_SETUP_WEB = '/onboarding/setuphomedepot/web';
export const SAVED_ROUTE = '/saved';
export const HOME_ROUTE = '/home';
export const REWARDS_ROUTE = '/rewards';
export const HOME_OR_SAVED_ROUTE_REGEX = /^\/serviceFusion\/(?:home|saved)(\/)?$/;

export const isOnboarding = () => window?.location?.pathname?.includes(ONBOARDING_BASE_ROUTE);
