import {
  Alert, Box, CircularProgress, Skeleton, Snackbar, Stack, TextField, Typography,
  useTheme, Theme,
} from '@mui/material';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import { makeStyles } from '@mui/styles';
import cloneDeep from 'lodash/cloneDeep';
import { Question } from '../../common/models/Question';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import * as onboardingAPI from '../../api/onboardingAPI';
import {
  selectBusinessInformationQuestions,
  selectMemberInformation,
  selectMemberSession,
  selectGetMemberInformationStatus,
  selectGetOnboardingFormStatus,
  selectGetOnboardingFormAnswersStatus,
  selectBusinessInformationAnswers,
  selectOnboardingForm,
} from '../../store/slices/onboardingSlice';
import InputComponent from '../../common/QuestionComponent';
import { getMemberInformation, getOnboardingForm, getOnboardingFormAnswers } from '../../store/thunks/onboardingThunk';
import { useTracking } from '../../common/useTracking';
import CallToActionButton from '../../common/CallToActionButton';
import { Answer } from '../../common/models/Answer';
import { BusinessTrade } from '../../common/models/Member';
import { updateIsFormDirtyState } from '../../common/utils/formUtils';
import { FollowTopicsCard } from '../Content/FollowTopicsCard';
import { getFollowTopics } from '../../store/thunks/contentThunk';
import { useSolutionOrg } from '../../config/useSolutionOrg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '13px',
    border: `1px solid ${theme.palette.color8.main}`,
  },
}));

export function Preferences() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const businessInformationQuestions = useAppSelector(selectBusinessInformationQuestions);
  const businessInformationAnswers = useAppSelector(selectBusinessInformationAnswers);
  const questionTitleAndIdMap = new Map<string, string>();
  businessInformationQuestions.forEach((question: Question, index: number) => {
    questionTitleAndIdMap.set(question.meta?.memberProperty as string ||
      question.title + index, question.id);
  });
  const onboardForm = useAppSelector(selectOnboardingForm);
  const memberInformation = useAppSelector(selectMemberInformation);
  const memberSession = useAppSelector(selectMemberSession);
  const getMemberInformationStatus = useAppSelector(selectGetMemberInformationStatus);
  const getOnboardingFormStatus = useAppSelector(selectGetOnboardingFormStatus);
  const getOnboardingFormAnswersStatus = useAppSelector(selectGetOnboardingFormAnswersStatus);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const trackEvent = useTracking();
  const [isDirtyMap, setIsDirtyMap] = useState<Map<string, boolean>>(new Map<string, boolean>());
  const [isDirty, setIsDirty] = useState(false);
  const { envSolutionOrgFormId } = useSolutionOrg();
  const onboardFormId = envSolutionOrgFormId as string;
  const businessMainIndustryKey = 'businessMainIndustry';
  const isFetchPreferencePageResources = getMemberInformationStatus === 'pending' ||
    getOnboardingFormStatus === 'pending' ||
    getOnboardingFormAnswersStatus === 'pending';

  const {
    handleSubmit,
    control,
    formState,
    trigger,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    console.log(`isDirtyMap: ${Array.from(isDirtyMap)}`);
    console.log(`isDirty: ${isDirty}`);
    console.log(`is form state valid: ${formState.isValid}`);
  });

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchPreferencePageResources]);

  const getPreferencePageResources = () => {
    const getOnboardingFormResponse = dispatch(getOnboardingForm(
      {
        formId: onboardFormId || '',
        trackEvent,
      },
    ))
      .then(unwrapResult);
    const getMemberInformationResponse = dispatch(getMemberInformation(
      {
        id: memberSession.memberId,
        trackEvent,
      },
    ))
      .then(unwrapResult);
    const getOnboardingFormAnswersResponse = dispatch(getOnboardingFormAnswers(
      {
        formId: onboardFormId || '',
        trackEvent,
      },
    ))
      .then(unwrapResult);
    Promise.all([
      getOnboardingFormResponse,
      getMemberInformationResponse,
      getOnboardingFormAnswersResponse,
    ])
      .then(() => {
        setOpenErrorSnackbar(false);
      })
      .catch(() => {
        setOpenErrorSnackbar(true);
      })
      .finally(() => {
        setIsDirtyMap(new Map<string, boolean>());
        setIsDirty(false);
      });
  };

  useEffect(() => {
    getPreferencePageResources();
    dispatch(getFollowTopics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  const getSelectedTradesInAscendingOrder = () => {
    const sortedBusinessTrades = cloneDeep(memberInformation.company?.businessTrades);
    return sortedBusinessTrades?.sort((a, b) => a.order - b.order);
  };

  const getDefaultValue = (question: Question) => {
    switch (question.meta?.behavior) {
      case 'autoComplete':
        return getSelectedTradesInAscendingOrder();
      case 'groupDropdown':
        try {
          return JSON.parse(businessInformationAnswers?.find(
            (item) => item?.question?.id === question?.id,
          )?.value as string);
        } catch {
          return null;
        }
      default:
        return null;
    }
  };

  const updateBusinessInformationAnswers = (
    updatedBusinessTrades: BusinessTrade[] | undefined,
    businessAnswers: Answer[],
  ) => {
    const updateBusinessTradePromise = onboardingAPI.updateBusinessTrade(
      updatedBusinessTrades,
      memberInformation.company?.id as string,
    );

    const businessAnswersPromises = businessAnswers.map(
      (answer) => {
        if (answer?.answerId) {
          return onboardingAPI.updateBusinessAnswer(answer.value, answer.answerId as string);
        }
        const createAnswerRequest = {
          memberId: memberSession.memberId,
          formId: onboardForm.id,
          answers: [{
            questionId: answer.questionId,
            value: answer.value,
          }],
        };

        return onboardingAPI.createBusinessAnswer(createAnswerRequest);
      },
    );

    Promise.all([updateBusinessTradePromise, ...businessAnswersPromises])
      .then(() => {
        setOpenSuccessSnackbar(true);
        setOpenErrorSnackbar(false);
        setLoading(false);
        getPreferencePageResources();
      })
      .catch((error: unknown) => {
        setOpenSuccessSnackbar(false);
        setOpenErrorSnackbar(true);
        setLoading(false);
      });
  };

  const onSubmit = (data: FieldValues) => {
    console.log(`data: ${JSON.stringify(data)}`);
    if (data) {
      let updatedBusinessTrades: BusinessTrade[] | undefined;
      const answers: Answer[] = [];
      Object.keys(data).forEach((key: string) => {
        if (key === businessMainIndustryKey) {
          // Get selected business trades
          updatedBusinessTrades = (data[key] as BusinessTrade[]).map(((item) => ({
            name: item.name,
            order: item.order,
          })));
        }
        const questionId = questionTitleAndIdMap.get(key);
        const answerId = businessInformationAnswers.find(
          (item) => item.question.id === questionId,
        )?.id as string;
        const question = businessInformationQuestions
          .find((item) => item.id === questionId) as Question;
        if (!(question?.meta?.memberProperty)) { // goes to member object
          // goes to answer object
          const answer: Answer = {
            questionId: questionId || '',
            answerId,
            value: JSON.stringify(data[key]),
          };
          answers.push(answer);
        }
      });
      setLoading(true);
      updateBusinessInformationAnswers(updatedBusinessTrades, answers);
    }
  };

  const parseToJson = (value: string): unknown => {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  };

  const PreferenceSkeleton = () => (<>
    <Skeleton
      animation="wave"
      height={15}
      width="70%"
      sx={() => ({
        marginBottom: theme.spacing(0.25),
      })}
    />
    <TextField
      type="text"
      disabled
      InputProps={{
        className: classes.root,
      }}
      sx={{
        width: '100%',
      }}
    />
    <Skeleton
      animation="wave"
      height={15}
      width="70%"
      sx={() => ({
        marginBottom: theme.spacing(0.25),
        marginTop: theme.spacing(3),
      })}
    />
    <TextField
      type="text"
      disabled
      InputProps={{
        className: classes.root,
      }}
      sx={{
        width: '100%',
      }}
    />
    <Skeleton
      animation="wave"
      height={15}
      width="70%"
      sx={() => ({
        marginBottom: theme.spacing(0.25),
        marginTop: theme.spacing(3),
      })}
    />
    <TextField
      type="text"
      disabled
      InputProps={{
        className: classes.root,
      }}
      sx={{
        width: '100%',
      }}
    />
    <Skeleton
      animation="wave"
      height={55}
      variant="rectangular"
      sx={() => ({
        marginTop: theme.spacing(4),
        borderRadius: theme.spacing(4),
      })}
    />
  </>);

  return (
    <>
      <Typography variant='body3' sx={
        () => ({
          marginBottom: theme.spacing(1.25),
        })}
      >Preferences</Typography>
      <Box>
        <Stack
          spacing={0}
          sx={() => ({
            borderRadius: theme.spacing(1.25),
            backgroundColor: theme.palette.common.white,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(5),
            marginTop: theme.spacing(2),
          })}
        >
          {isFetchPreferencePageResources ?
            < PreferenceSkeleton /> :
            <form onSubmit={handleSubmit(onSubmit)}>
              <Snackbar
                open={openSuccessSnackbar}
                autoHideDuration={1000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Alert severity="success" sx={{ width: '100%' }}>
                  {'update successful'}
                </Alert>
              </Snackbar>
              <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Alert severity="error" sx={{ width: '100%' }}>
                  {t('error.generalMessage')}
                </Alert>
              </Snackbar>
              {businessInformationQuestions
                .map((question: Question, index: number) => {
                  const controllerName = question.meta?.memberProperty as string ||
                    question.title + index;
                  return (
                    <Box key={question.id}>
                      <Typography sx={{
                        mt: index === 0 ? 0 : 3,
                        textAlign: 'left',
                        color: theme.palette.color2.main,
                      }}>
                        {question?.meta?.memberProperty === businessMainIndustryKey ? 'Business or Trade' : t(`onboarding.questionPlaceholder.${question.title}`)}
                      </Typography>
                      <Controller
                        name={controllerName}
                        control={control}
                        rules={question.meta?.behavior === 'autoComplete' ?
                          {
                            required: 'The primary business or trade is required',
                          } : (question.meta?.rules || {})
                        }
                        defaultValue={getDefaultValue(question)}
                        render={(
                          {
                            field: {
                              onChange, value, onBlur,
                            },
                          },
                        ) => (
                          <div>
                            <InputComponent
                              type={question.type}
                              onChange={(e) => {
                                updateIsFormDirtyState(
                                  isDirtyMap,
                                  controllerName,
                                  !(_isEqual(e, getDefaultValue(question))),
                                  setIsDirtyMap,
                                  setIsDirty,
                                );
                                // Ensure React Hook Form's internal state is updated
                                onChange(e);
                              }}
                              selectedOptions={getSelectedTradesInAscendingOrder()}
                              // selectedOptions={memberInformation?.company?.businessTrades}
                              inputValue={value}
                              meta={question.meta}
                              options={question.options}
                              placeholderText={question.title ? question.title : ''}
                              subTitle={question.meta?.subTitle as string}
                              groupDropDownDefaultValues={
                                question.meta?.behavior === 'groupDropdown' ?
                                  parseToJson(businessInformationAnswers.find(
                                    (item) => item.question.id === question.id,
                                  )?.value as string) :
                                  undefined
                              }
                              groupAutoComplete={question.meta?.behavior === 'autoComplete'}
                              onBlur={onBlur}
                              borderStyle={true}
                            />
                          </div>
                        )
                        }
                      />
                      {formState.touchedFields[controllerName] &&
                        formState.errors[controllerName]?.message &&
                        <Alert
                          sx={() => ({
                            backgroundColor: theme.palette.grey[100],
                            color: theme.palette.error.main,
                            p: 0,
                          })}
                          severity='error'>
                          {formState.errors[controllerName]?.message?.toString()}
                        </Alert>}
                    </Box>
                  );
                })}
              <CallToActionButton
                text={loading ?
                  <CircularProgress color="inherit" size={24} /> : 'Update'}
                disable={!isDirty || !(formState.isValid) || loading}
                type='submit'
                sx={() => ({
                  position: 'static',
                  width: '100%',
                  marginTop: theme.spacing(4),
                  [theme.breakpoints.up('sm')]: {
                    width: '100%',
                    marginLeft: '0px',
                  },
                  background: theme.palette.secondary.main,
                  borderRadius: '30px',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                  '&:disabled': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                })}
              />
            </form>
          }
        </Stack>
        {/* suggested topics */}
        <Box
          sx={{
            marginTop: theme.spacing(7),
          }}>
          <Typography variant='body3' sx={
            () => ({
              marginBottom: theme.spacing(1.25),
            })}
          >{t('preferences.followTopics.heading')}</Typography>
          <Typography sx={{
            mb: theme.spacing(0.25),
            textAlign: 'left',
            color: theme.palette.color2.main,
          }}> {t('preferences.followTopics.description')}
          </Typography>
          <FollowTopicsCard
            isHomeCard={false}
          />
        </Box>
      </Box>
    </>
  );
}
