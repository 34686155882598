/* eslint-disable no-nested-ternary */
import {
  Box, Typography, Theme, useMediaQuery, useTheme,
  Grid,
  Link,
  Snackbar,
  Alert,
  TextField,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import { AddMemberToPartnerInput } from '@optimus/models';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ReactComponent as HDSupplyLogo } from '../../../assets/images/hdSupply_logo.svg';
import { ReactComponent as CompletedBadge } from '../../../assets/images/completed_badge.svg';
import { ReactComponent as PendingBadge } from '../../../assets/images/pending_badge.svg';
import { HDSupplySignupFormDialog } from './HDSupplySignupFormDialog';
import { getHDSupplyMemberRelation, updateMemberToPartner } from '../../../store/thunks/dashboardThunk';
import CallToActionButton from '../../../common/CallToActionButton';
import {
  selectHDSupplyEnrollmentState,
  UpdateHDSupplyEnrollmentState,
  selectGetHDSupplyMemberRelationState,
  selectHDSupplyMemberRelation,
} from '../../../store/slices/dashboardSlice';
import { selectMemberSession } from '../../../store/slices/onboardingSlice';
import LennoxAccountNumberCardSkeleton from '../../../common/LennoxAccountNumberCardSkeleton';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import { DialogPopup } from '../../../common/DialogPopup';
import { deleteMemberPartnerRelation } from '../../../api/dashboardAPI';
import { HDSupplyEnrollmentStatus } from '../../../common/enums';

const cardStyle = (theme: Theme) => ({
  background: theme.palette.common.white,
  borderRadius: theme.spacing(1.625),
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  paddingTop: theme.spacing(3),
  marginTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
} as const);

const buttonStyle = (theme: Theme) => ({
  position: 'static',
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  background: theme.palette.secondary.main,
  borderRadius: theme.spacing(3.75),
  fontWeight: '600',
  fontSize: theme.spacing(2.3),
  '&:hover': {
    backgroundColor: theme.palette.color1.main,
  },
  '&:disabled': {
    backgroundColor: theme.palette.color1.main,
    opacity: '0.4',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginLeft: '0px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '60% !important',
    marginLeft: '0px',
  },
} as const);

interface HDSupplyEnrollmentCardProps {
  optionalCardStyle: (theme: Theme) => {
    [key: string]: unknown;
  },
  optionalBtnStyles?: {
    [k: string]: unknown;
  };
  inputStyles?: {
    [k: string]: unknown;
  };
}

interface HDSupplyEnrollmentCardHeaderProps {
  children: JSX.Element
}

interface IFormInputs {
  hdSupplyAccountNumber: string
}

export default function HDSupplyEnrollmentCard({
  optionalCardStyle,
  optionalBtnStyles,
  inputStyles,
}: HDSupplyEnrollmentCardProps) {
  const { t } = useTranslation();
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const dispatch = useAppDispatch();
  const { accessToken, memberId } = useAppSelector(selectMemberSession);
  const getMemberPartnerRelationState = useAppSelector(selectGetHDSupplyMemberRelationState);
  const hdSupplyMemberRelation = useAppSelector(selectHDSupplyMemberRelation);
  const storeHDSupplyEnrollmentStatus = useAppSelector(selectHDSupplyEnrollmentState);
  const trackOnboardEvent = useOnboardEventTracking();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openUnlinkPopup, setOpenUnlinkPopup] = useState(false);
  const [haveExistingAccount, setHaveExistingAccount] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const hdSupplyPartnerId = process.env.REACT_APP_HD_SUPPLY_PARTNER_ID || '';
  const [HDSupplyCardComponent, setHDSupplyCardComponent] =
    useState<React.FC | null>(null);
  const {
    handleSubmit,
    control,
    formState,
    reset,
  } = useForm<IFormInputs>({ mode: 'onChange' });

  useEffect(() => {
    if (accessToken && hdSupplyPartnerId &&
      !(hdSupplyMemberRelation?.partnerMemberKey)) {
      dispatch(getHDSupplyMemberRelation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const onSubmit = handleSubmit((data: IFormInputs) => {
    if (data.hdSupplyAccountNumber) {
      const request: AddMemberToPartnerInput = {
        partnerMemberKey: data.hdSupplyAccountNumber,
      };

      dispatch(updateMemberToPartner({
        memberId,
        partnerId: hdSupplyPartnerId,
        data: request,
      }))
        .then(unwrapResult)
        .then(() => {
          reset({ hdSupplyAccountNumber: '' });
          dispatch(getHDSupplyMemberRelation());
          dispatch(UpdateHDSupplyEnrollmentState(HDSupplyEnrollmentStatus.Submitted));
          setOpenSuccessSnackbar(true);
          setOpenErrorSnackbar(false);
        })
        .catch((error: unknown) => {
          setOpenSuccessSnackbar(false);
          setOpenErrorSnackbar(true);
        });
    }
  });

  const handleHdUnlink = () => {
    deleteMemberPartnerRelation(
      memberId,
      hdSupplyPartnerId,
    ).then((res) => {
      // Populate user event
      setHaveExistingAccount(false);
      dispatch(UpdateHDSupplyEnrollmentState(HDSupplyEnrollmentStatus.Pending));
      dispatch(getHDSupplyMemberRelation());
      trackOnboardEvent('unlink-hd-supply-account', {
        hdSupplyMemberKey: hdSupplyMemberRelation?.partnerMemberKey,
      });
    });
  };

  const HDSupplyEnrollmentCardHeader = ({ children }: HDSupplyEnrollmentCardHeaderProps) => (
    <Box display={'flex'} sx={{ marginBottom: theme.spacing(3) }}>
      <Box display={'flex'} alignItems={'center'}>
        <HDSupplyLogo
          width={theme.spacing(6)}
          height={theme.spacing(6)}
        />
        <Typography variant='h4' component='div' sx={{
          marginBottom: theme.spacing(1.5),
          marginLeft: theme.spacing(2.5),
          marginRight: theme.spacing(2.5),
        }}>
          {t('hdSupply.heading')}
        </Typography>
      </Box>
      {children}
    </Box>
  );

  const HDSupplySignupCard = () => (
    <>
      <Typography
        sx={{
          color: theme.palette.color2.main,
          marginBottom: theme.spacing(1),
        }}>
        {t('hdSupply.signUpDescription')}
      </Typography>
      <Grid container
        sx={{
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Grid item xs={12} sm={12} md={4} lg={4}
          sx={{
            justifyContent: 'center !important',
            [theme.breakpoints.down('md')]: {
              justifyContent: 'center',
            },
            '& .MuiStack-root': {
              justifyContent: 'center',
            },
          }}
        >
          <CallToActionButton
            text={`${t('button.signUpNow')}`}
            type={'submit'}
            onClick={() => setOpen(true)}
            sx={[buttonStyle, ...(optionalBtnStyles ? [optionalBtnStyles] : [])]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}
          sx={{
            ml: 'auto',
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
              mt: theme.spacing(1),
            },
          }}
        >
          <Link
            underline='none'
            variant='h4'
            color={theme.palette.secondary.main}
            onClick={() => setHaveExistingAccount(true)}
            sx={{
              cursor: 'pointer',
            }}
          >
            {t('hdSupply.existingAccountLink')}
          </Link>
        </Grid>
      </Grid>
    </>
  );

  const HDSupplyEnrollmentDoneCard = () => (
    <Box>
      <Box sx={{
        marginBottom: theme.spacing(3),
        color: theme.palette.color2.main,
      }}>
        <Typography component='span'>
          {t('hdSupply.successMessage')}
        </Typography>
        <Typography component='span' sx={{ fontWeight: 600 }}>
          {hdSupplyMemberRelation?.partnerMemberKey || ''}
        </Typography>
      </Box>
      <Divider sx={{ marginBottom: theme.spacing(3) }} />
      <Box
        sx={{
          mb: theme.spacing(3),
        }}>
        <Typography component='span' sx={{
          marginRight: theme.spacing(1),
        }}>
          {t('verification.unlinkMessage')}
        </Typography>
        <Typography component='span'>
          <Link
            component="button"
            variant="body2"
            color={theme.palette.secondary.main}
            onClick={() => setOpenUnlinkPopup(true)}
          >
            {t('hdSupply.unlinkHDSupplyAccount')}
          </Link>
        </Typography>
      </Box>
    </Box>
  );

  const HDSupplyAccountNumberForm = () => (
    <>
      <Box>
        <form onSubmit={onSubmit}>
          <Typography sx={{ color: theme.palette.color2.main }}>
            {t('hdSupply.enterAccountNumber')}
          </Typography>
          <Grid container
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              pt: theme.spacing(1),
              width: '100%',
            }}
          >
            <Grid item xs={12} sm={7} md={8} lg={7.5}
              sx={{
                [theme.breakpoints.between('sm', 'md')]: {
                  ml: theme.spacing(13),
                },
                [theme.breakpoints.down('sm')]: {
                  mt: theme.spacing(3),
                },
              }}
            >
              <Controller
                name="hdSupplyAccountNumber"
                control={control}
                defaultValue={hdSupplyMemberRelation?.partnerMemberKey || ''}
                render={({
                  field: {
                    onChange, onBlur, value,
                  },
                }) => <TextField
                    onChange={(event) => {
                      onChange(event);
                    }}
                    onBlur={onBlur}
                    value={value}
                    type="text"
                    placeholder='HD Supply Account NUmber'
                    InputProps={{
                      sx: {
                        borderRadius: theme.spacing(13),
                        border: `1px solid ${theme.palette.color8.main}`,
                        background: theme.palette.common.white,
                        fontWeight: '400',
                        fontSize: theme.spacing(2),
                        paddingLeft: theme.spacing(2),
                      },
                    }}
                    sx={{
                      width: '100%',
                      ...(inputStyles && inputStyles),
                    }}
                  />}
              />
              {(formState?.errors?.hdSupplyAccountNumber &&
                formState.errors.hdSupplyAccountNumber.type !== 'required') &&
                <Alert
                  sx={{
                    background: theme.palette.common.white,
                    color: theme.palette.error.main,
                    p: 0,
                  }}
                  severity='error'>
                  {formState?.errors.hdSupplyAccountNumber.message}
                </Alert>}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={4}
              sx={{
                ml: 'auto',
                [theme.breakpoints.down('md')]: {
                  marginTop: theme.spacing(2),
                },
              }}
            >
              <CallToActionButton
                text={`${t('button.confirm')}`}
                type={'submit'}
                sx={{
                  position: 'static',
                  width: '100%',
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(3),
                  paddingTop: theme.spacing(0.5),
                  paddingBottom: theme.spacing(0.5),
                  background: theme.palette.color1.main,
                  borderRadius: theme.spacing(3.75),
                  fontWeight: '600',
                  fontSize: theme.spacing(2.35),
                  '&:hover': {
                    backgroundColor: theme.palette.color1.main,
                  },
                  '&:disabled': {
                    backgroundColor: theme.palette.color1.main,
                    opacity: '0.4',
                    color: theme.palette.common.white,
                  },
                  [theme.breakpoints.up('sm')]: {
                    width: '100%',
                    marginLeft: '0px',
                  },
                  ...(optionalBtnStyles && optionalBtnStyles),
                }}
              />
            </Grid>
          </Grid>
        </form >
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Link
            underline='none'
            variant='h6'
            color={theme.palette.secondary.main}
            onClick={() => setHaveExistingAccount(false)}
            sx={{
              cursor: 'pointer',
            }}
          >
            {t('hdSupply.noExistingAccountLink')}
          </Link>
        </Box>
      </Box >
    </>
  );

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  useEffect(() => {
    if (hdSupplyMemberRelation) {
      if (hdSupplyMemberRelation?.partnerMemberKey) {
        // Done state
        dispatch(UpdateHDSupplyEnrollmentState(HDSupplyEnrollmentStatus.Done));
        setHDSupplyCardComponent(() => (
          () => <HDSupplyEnrollmentDoneCard />
        ));
      } else {
        dispatch(UpdateHDSupplyEnrollmentState(HDSupplyEnrollmentStatus.Submitted));
        setHDSupplyCardComponent(() => (
          () => <Typography sx={{ color: theme.palette.color2.main }}>
            {t('hdSupply.formSuccessfullySent')}
          </Typography>
        ));
      }
    } else {
      setHDSupplyCardComponent(() => (
        () => (haveExistingAccount ? <HDSupplyAccountNumberForm /> : <HDSupplySignupCard />)
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storeHDSupplyEnrollmentStatus, haveExistingAccount,
    hdSupplyMemberRelation,
  ]);

  return (
    <>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('success.dashboard')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('error.generalMessage')}
        </Alert>
      </Snackbar>
      {getMemberPartnerRelationState === 'pending' ? <LennoxAccountNumberCardSkeleton /> :
        <Box
          sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
        >
          <HDSupplyEnrollmentCardHeader>
            {storeHDSupplyEnrollmentStatus === HDSupplyEnrollmentStatus.Done ? <CompletedBadge
              style={{ marginTop: theme.spacing(1) }} /> : <PendingBadge
              style={{ marginTop: theme.spacing(1) }} />}

          </HDSupplyEnrollmentCardHeader>
          {HDSupplyCardComponent && <HDSupplyCardComponent />}
        </Box >}
      <Box>
        {
          open && <HDSupplySignupFormDialog
            title={t('homeDepot.instructions.dialogConfirmation.title')}
            confirmBtnText={t('button.confirmAndLink')}
            cancelBtnText={t('button.cancel')}
            description={t('homeDepot.instructions.dialogConfirmation.description')}
            updateOpenState={(newState: boolean) => setOpen(newState)}
          />
        }
        {
          openUnlinkPopup && <DialogPopup
            title={t('hdSupply.unlinkDialogConfirmation.title')}
            confirmBtnText={t('button.confirmAndLink')}
            cancelBtnText={t('button.cancel')}
            description={t('hdSupply.unlinkDialogConfirmation.description')}
            onBtnClick={handleHdUnlink}
            updateOpenState={(newState: boolean) => setOpenUnlinkPopup(newState)}
          />
        }
      </Box>
    </>
  );
}
