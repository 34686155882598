import React from 'react';
import {
  Box, Divider, Grid, IconButton, Link, Typography, useTheme,
} from '@mui/material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { useTranslation } from 'react-i18next';
import { HDVerificationStatus } from './enums';
import { Badge } from './Badge';

interface PartnerDescription {
  name: string;
  description: string;
  perks: string[];
  links: {text: string, url?: string}[]
}

interface PartnerCardProps {
  PartnerLogo: React.ComponentType;
  partnerInfo: PartnerDescription;
  enrolled: HDVerificationStatus;
}

export function PartnerCard({ PartnerLogo, partnerInfo, enrolled }: PartnerCardProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderBadge = (enrollStatus: HDVerificationStatus) => {
    if (enrollStatus === 'pending') {
      return (
        <Badge
          badgeType="notEnrolled"
          badgeText={t('button.enrollToday')}
          isButton={true}
          optionalStyles={{
            backgroundColor: theme.palette.color21.main,
            borderColor: theme.palette.color22.main,
          }}
        />
      );
    }

    if (enrollStatus === 'completed') {
      return (
        <Badge
          badgeType="enrolled"
          badgeText={t('button.enrolled')}
          badgeSubtitle={t('partners.noCashBack')}
          optionalStyles={{
            backgroundColor: theme.palette.color23.main,
            borderColor: theme.palette.color24.main,
          }}
        />
      );
    }

    return (
      <Badge
        badgeType="enrolled"
        badgeText={t('button.enrollToday')}
        badgeSubtitle={t('error.notFound')}
        optionalStyles={{
          backgroundColor: theme.palette.color21.main,
          borderColor: theme.palette.color22.main,
        }}
      />
    );
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      width: '100%',
      height: '100%',
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1.6),
      mb: theme.spacing(6),
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 0px 4px 0px rgba(0, 0, 0, 0.02), 0px 0px 8px 0px rgba(0, 0, 0, 0.04)',
    }}>

      <Grid container spacing={3}>
        {/* Partner Title Info */}
        <Grid item xs={12} md={9}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            gap: theme.spacing(2),
          }}>
            {/* Logo */}
            <Box
              sx={{
                border: `${theme.spacing(0.125)} solid ${theme.palette.grey2.main}`,
                borderRadius: theme.spacing(1),
                padding: theme.spacing(2),
                backgroundColor: theme.palette.common.white,
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  alignSelf: 'center',
                  width: theme.spacing(13),
                  height: theme.spacing(9),
                  '& svg': {
                    width: '100%',
                    height: '100%',
                  },
                },
              }}
            >
              <PartnerLogo />
            </Box>

            {/* Name and Perks */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                ml: theme.spacing(3),
                gap: theme.spacing(2),
                [theme.breakpoints.down('md')]: {
                  ml: theme.spacing(0),
                },
              }}
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'self-start',
                [theme.breakpoints.down('md')]: {
                  alignSelf: 'center',
                  width: '100%',
                  justifyContent: 'self-start',
                },
              }}>
                <Typography variant="h2"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: theme.spacing(3.75),
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.spacing(2.5),
                      mb: theme.spacing(0),
                    },
                  }}
                >
                  {partnerInfo.name}
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}>
                {partnerInfo.perks.map((perk, index) => (
                  <Box key={index} sx={{
                    mr: theme.spacing(1),
                    [theme.breakpoints.down('lg')]: {
                      mb: theme.spacing(1),
                    },
                  }}>
                    <Badge
                      badgeType='checkmark'
                      badgeText={perk}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Enrollment Info */}
        <Grid item xs={12} md={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'flex-end' }, // Center on small screens, right-align on larger screens
          }}
        >
          {renderBadge(enrolled)}
        </Grid>
      </Grid>

      {/* Partner Description */}
      <Box sx={{
        mt: theme.spacing(3),
        width: '100%',
        textAlign: 'left',
      }}>
        <Typography
          variant="body1"
          sx={{
            lineHeight: theme.spacing(2.75),
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.spacing(1.75),
              lineHeight: theme.spacing(2.5),
            },
          }}
        >
          {partnerInfo.description}
        </Typography>
      </Box>

      <Divider sx={{
        mb: theme.spacing(3),
        mt: theme.spacing(3),
        height: theme.spacing(0.125),
        backgroundColor: theme.palette.grey2.main,
        marginLeft: `-${theme.spacing(3)}`, // Negate the Parent padding value
        marginRight: `-${theme.spacing(3)}`,
      }}/>

      {/* Aditional Info */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
      }}>
        <Typography
          variant='h2'
          sx={{
            lineHeight: theme.spacing(3),
            textAlign: 'left',
            mb: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.spacing(2.25),
              fontWeight: 400,
              lineHeight: theme.spacing(3),
              letterSpacing: `-${theme.spacing(0.05)}`,
            },
          }}
        >
          {t('partners.benefitsTitle')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {partnerInfo.links.map((link, index) => (
            <Box
              key={index}
              sx={{
                mb: theme.spacing(1.25),
                mr: theme.spacing(1.25),
                display: 'flex',
                alignItems: 'stretch',
                flex: 'none',
                [theme.breakpoints.down('sm')]: {
                  maxWidth: '100%',
                },
              }}
            >
              <Box
                sx={{
                  border: `${theme.spacing(0.125)} solid ${theme.palette.grey2.main}`,
                  borderRadius: `${theme.spacing(1)} ${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(1)}`,
                  padding: theme.spacing(1.25),
                  backgroundColor: theme.palette.common.white,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: theme.spacing(2.75),
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.spacing(1.75),
                      lineHeight: theme.spacing(2.5),
                    },
                  }}
                >
                  {link.text}
                </Typography>
              </Box>
              {link.url && (
                <Box
                  sx={{
                    border: `${theme.spacing(0.125)} solid ${theme.palette.grey2.main}`,
                    borderLeft: 'none',
                    borderRadius: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(0)}`,
                    padding: theme.spacing(1),
                    backgroundColor: theme.palette.grey[100],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Link href={link.url} target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{ padding: theme.spacing(0) }}>
                      <LaunchOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
