/* eslint-disable no-shadow */
import {
  Box,
  Checkbox,

  Grid,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import {
  Controller, useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectMemberInformation } from '../../../store/slices/onboardingSlice';
import { useAppSelector } from '../../../store/hooks';
import {
  Body8VariantTypography, EditOption, ErrorAlert, IFormInputs, StyledSelect, StyledTextField,
} from './HDSupplySignupFormDialog';
import { ValidFieldPattern } from '../../Onboarding/constants';

export enum CompanyBusinessType {
  SOLE_PROPRIETORSHIP = 'Sole Proprietorship',
  PARTNERSHIP = 'Partnership',
  CORPORATION = 'Corporation',
  LLC = 'LLC'
}

export function BusinessInformationFormControl() {
  const theme = useTheme();
  const { t } = useTranslation();
  const memberInfo = useAppSelector(selectMemberInformation);

  const [editBusinessAddress, setEditBusinessAddress] = useState(false);
  const [isBillingAndBusinessAddressSame, setIsBillingAndBusinessAddressSame] = useState(true);
  const {
    control, register, formState: { errors }, clearErrors,
  } = useFormContext<IFormInputs>();

  const handleOnChange = () => {
    clearErrors('businessInfo.businessType');
  };

  return (
    <>
      <Grid container spacing={2} mt={theme.spacing(1)}>
        <Grid item xs={8} sm={10} md={10} lg={10}>
          <Typography variant="h2" gutterBottom>
            {t('hdSupply.businessInformation.heading')}
          </Typography>
        </Grid>
        {!editBusinessAddress &&
          <Grid item xs={4} sm={2} md={2} lg={2}>
            <EditOption updateEditState={(newState: boolean) => setEditBusinessAddress(newState)} />
          </Grid>
        }
      </Grid>
      {editBusinessAddress ? <Box
        sx={{
          mt: theme.spacing(1),
          mb: theme.spacing(2),
        }}
      >
        <Box sx={{ mb: theme.spacing(2) }}>
          <Body8VariantTypography>{`${t('hdSupply.businessInformation.name')}*` || ''}</Body8VariantTypography>
          <StyledTextField
            placeholder='Name'
            {...register('businessInfo.name', { required: true })}
          />
          {(errors.businessInfo?.name) && <ErrorAlert />}
        </Box>
        <Box sx={{ mb: theme.spacing(2) }}>
          <Body8VariantTypography>{`${t('hdSupply.businessInformation.email')}*` || ''}</Body8VariantTypography>
          <StyledTextField
            placeholder='Email'
            {...register('businessInfo.email', {
              required: true,
              pattern: {
                value: ValidFieldPattern.validEmailPattern,
                message: `${t('error.invalidEmail2')}`,
              },
            })}
          />
          {(errors.businessInfo?.email) && <ErrorAlert
            errorMessage={errors.businessInfo?.email?.message} />}
        </Box>
        <Box sx={{ mb: theme.spacing(2) }}>
          <Body8VariantTypography>{`${t('hdSupply.businessInformation.phone')}*` || ''}</Body8VariantTypography>
          <StyledTextField
            placeholder='Phone'
            {...register('businessInfo.phone', {
              required: true,
              validate: (value) => isValidPhoneNumber(value, 'US') || `${t('error.invalidPhoneNumber')}`,
            })}
          />
          {(errors.businessInfo?.phone) &&
            <ErrorAlert errorMessage={errors.businessInfo?.phone?.message} />}
        </Box>
        <Box>
          <Typography variant='h6'>{t('hdSupply.businessInformation.address.heading') || ''}</Typography>
          <Box sx={{ mb: theme.spacing(2) }}>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.street')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='Street'
              {...register('businessInfo.address.street', { required: true })}
            />
            {(errors.businessInfo?.address?.street) && <ErrorAlert />}
          </Box>
          <Box sx={{ mb: theme.spacing(2) }}>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.city')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='City'
              {...register('businessInfo.address.city', { required: true })}
            />
            {(errors.businessInfo?.address?.city) && <ErrorAlert />}
          </Box>
          <Box sx={{ mb: theme.spacing(2) }}>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.state')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='State'
              {...register('businessInfo.address.state', { required: true })}
            />
            {(errors.businessInfo?.address?.state) && <ErrorAlert />}
          </Box>
          <Box sx={{ mb: theme.spacing(2) }}>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.country')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='Country'
              {...register('businessInfo.address.country', { required: true })}
            />
            {(errors.businessInfo?.address?.country) && <ErrorAlert />}
          </Box>
          <Box sx={{ mb: theme.spacing(2) }}>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.zipcode')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='Zipcode'
              {...register('businessInfo.address.zipCode', { required: true })}
            />
            {(errors.businessInfo?.address?.zipCode) && <ErrorAlert />}
          </Box>
        </Box>
      </Box> : <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mb: theme.spacing(2),
          mt: theme.spacing(1),
        }}
      >
        <Typography variant='body8' lineHeight={2}>
          <span style={{ fontSize: theme.spacing(1.75), fontWeight: 700 }}>
            {t('hdSupply.businessInformation.name')}: </span>
          {memberInfo.companyName}
        </Typography>
        <Typography variant='body8' lineHeight={2}>
          <span style={{ fontSize: theme.spacing(1.75), fontWeight: 700 }}>
            {t('hdSupply.businessInformation.email')}: </span>
          {memberInfo.companyEmail}
        </Typography>
        <Typography variant='body8' lineHeight={2}>
          <span style={{ fontSize: theme.spacing(1.75), fontWeight: 700 }}>
            {t('hdSupply.businessInformation.phone')}: </span>
          {memberInfo.companyPhoneNumber}
        </Typography>
        <Typography variant='body8' lineHeight={2}>
          <span style={{ fontSize: theme.spacing(1.75), fontWeight: 700 }}>
            {t('hdSupply.businessInformation.address.heading')}: </span>
          {`${memberInfo.companyAddressStreet} ${memberInfo.companyAddressCity} 
          ${memberInfo.companyAddressState} ${memberInfo.companyAddressZipCode}`}
        </Typography>
      </Box>}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>
          <Controller
            name="businessInfo.sameBusinessAndBillingAddress"
            control={control}
            render={({ field: props }) => (
              <Checkbox
                icon={<Box sx={{
                  border: `2px solid ${theme.palette.color8.main}`,
                  width: theme.spacing(2.25),
                  height: theme.spacing(2.25),
                }}></Box>}
                sx={{
                  '&.MuiCheckbox-root': {
                    paddingLeft: theme.spacing(0),
                    width: theme.spacing(4.125),
                    height: theme.spacing(4.125),
                    '&.Mui-checked': {
                      color: theme.palette.secondary.main,
                    },
                  },
                }}
                {...props}
                onChange={
                  (e) => {
                    props.onChange(e);
                    setIsBillingAndBusinessAddressSame(e.target.checked);
                  }
                }
                checked={isBillingAndBusinessAddressSame}
              />
            )}
          />
        </Box>
        <Body8VariantTypography>
          {t('hdSupply.businessInformation.billingAddress.sameAsBusinessAddress') || ''}
        </Body8VariantTypography>
      </Box>
      {!isBillingAndBusinessAddressSame &&
        <>
          <Typography
            sx={{ mt: theme.spacing(2) }}
            variant='h6'>{t('hdSupply.businessInformation.billingAddress.heading')}</Typography>
          <Box>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.street')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='Street'
              {...register('businessInfo.billingAddress.street', { required: true })}
            />
            {(errors.businessInfo?.billingAddress?.street) && <ErrorAlert />}
          </Box>
          <Box>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.city')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='City'
              {...register('businessInfo.billingAddress.city', { required: true })}
            />
            {(errors.businessInfo?.billingAddress?.city) && <ErrorAlert />}
          </Box>
          <Box>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.state')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='State'
              {...register('businessInfo.billingAddress.state', { required: true })}
            />
            {(errors.businessInfo?.billingAddress?.state) && <ErrorAlert />}
          </Box>
          <Box>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.country')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='Country'
              {...register('businessInfo.billingAddress.country', { required: true })}
            />
            {(errors.businessInfo?.billingAddress?.country) && <ErrorAlert />}
          </Box>
          <Box>
            <Body8VariantTypography>{`${t('hdSupply.businessInformation.address.zipcode')}*` || ''}</Body8VariantTypography>
            <StyledTextField
              placeholder='Zipcode'
              {...register('businessInfo.billingAddress.zipCode', { required: true })}
            />
            {(errors.businessInfo?.billingAddress?.zipCode) && <ErrorAlert />}
          </Box></>
      }
      <Box
        sx={{
          mt: theme.spacing(1),
        }}
      >
        <Typography variant='body8'
          sx={{
            marginBottom: theme.spacing(6),
          }}
        >{t('hdSupply.businessInformation.type')}*</Typography>
        <StyledSelect
          displayEmpty
          {...register('businessInfo.businessType', { required: true })}
          onChange={(e) => { handleOnChange(); }}
          defaultValue=''
        >
          <MenuItem disabled value=''>
            Select business type
          </MenuItem>
          {Object.values(CompanyBusinessType).map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
        {(errors.businessInfo?.businessType) && <ErrorAlert />}
      </Box>
    </>
  );
}
