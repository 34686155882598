import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import ContentHome from '../Content/ContentHome';
import { getMemberInformation } from '../../store/thunks/onboardingThunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
// import PaypalVerificationCard from '../../common/PaypalVerificationCard';
// import LennoxAccountNumberCard from '../../common/LennoxAccountNumberCard';
import { useTracking } from '../../common/useTracking';
import { HDSetupFlowCard } from './HDOnboardingFlow/HDSetupFlowCard';
import {
  selectCompanyEnrollmentInformation,
  selectMemberSession, selectNaviagtionFromOnboarding,
  updateNavigationFromOnboarding,
} from '../../store/slices/onboardingSlice';
import { useOnboardEventTracking } from '../../common/useOnboardEventTracking';
import { FollowTopicsCard } from '../Content/FollowTopicsCard';
import {
  selectFollowTopicsCardViewCount,
  UpdateFollowTopicsCardViewCount,
  selectBehrPromotionCardViewCount,
  UpdateBehrPromotionCardViewCount,
} from '../../store/slices/contentSlice';
import { updateMemberConfig } from '../../api/dashboardAPI';
import { selectMemberConfig } from '../../store/slices/dashboardSlice';
import { getMemberConfig } from '../../store/thunks/dashboardThunk';
import { getFollowTopics } from '../../store/thunks/contentThunk';
import { PromotionCard } from '../../common/PromotionCard';
import { ReactComponent as BehrLogo } from '../../assets/images/behr_logo.svg';
import { ReactComponent as KilzLogo } from '../../assets/images/kilz_logo.svg';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';

// optional card style
/* const optionalCardStyle = (theme: Theme) => ({
  marginTop: '24px',
  marginBottom: '0px',
  height: 'fit-content',
} as const); */

export function Dashboard() {
  // const { verificationEmailFlag, hdOnboardingForSNFlag } = useFeatureFlags();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(() => theme.breakpoints.up('md'));
  // const location = useLocation();
  // const navigate = useNavigate();
  const trackEvent = useTracking();
  const companyEnrollmentInformation = useAppSelector(selectCompanyEnrollmentInformation);
  const navigationFromOnboarding = useAppSelector(selectNaviagtionFromOnboarding);
  const trackOnboardEvent = useOnboardEventTracking();
  const [searchParams] = useSearchParams();
  const memberSession = useAppSelector(selectMemberSession);
  const storeMemberConfig = useAppSelector(selectMemberConfig);
  const followTopicsCardViewCount = useAppSelector(selectFollowTopicsCardViewCount);
  const behrPromotionCardViewCount = useAppSelector(selectBehrPromotionCardViewCount);
  const { discountFlag } = useFeatureFlags();
  const memberId = memberSession.memberId || searchParams.get('memberId');
  const [showFollowTopicsCard, setShowFollowTopicsCard] = useState(false);
  const [showBehrPromotionCard, setShowBehrPromotionCard] = useState(false);
  const [followTopicsCardViewed, setFollowTopicsCardViewed] = useState(false);
  const [behrPromotionCardViewed, setBehrPromotionCardViewed] = useState(false);
  const maxFollowTopicsCardViewCount = 3;
  const maxBehrPromotionCardViewCount = 6;

  const updateMemberConfigByKey = async (key: string, showStatus: boolean) => {
    try {
      await updateMemberConfig(
        { [key]: showStatus },
        memberId as string,
        storeMemberConfig?.id as string,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const hideFollowTopicsCard = () => {
    setShowFollowTopicsCard(false);
    updateMemberConfigByKey('showFollowTopicsCard', false); // save the hide setting in BE
    // reset the folow topics view count in store
    dispatch(UpdateFollowTopicsCardViewCount(0));

    // TODO: Add Track element Visibility
    // We will not need to update BehrPromotionCardViewCount when FollowTopic is closed
    dispatch(UpdateBehrPromotionCardViewCount(3));
  };

  const hideBehrPromotionCard = () => {
    setShowBehrPromotionCard(false);
    updateMemberConfigByKey('showBehrPromotion', false); // save the hide setting in BE
    // reset the behr promotion view count in store
    dispatch(UpdateBehrPromotionCardViewCount(0));
  };

  useEffect(() => {
    dispatch(getMemberInformation({ trackEvent }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (navigationFromOnboarding && companyEnrollmentInformation?.status) {
      dispatch(updateNavigationFromOnboarding(false));
      // track user lands on home feed after onboarding
      trackOnboardEvent('landed-on-the-home-feed-after-onboarding');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyEnrollmentInformation, navigationFromOnboarding]);

  useEffect(() => {
    // TODO: Add Track element Visibility to do real count
    // of followTopicsCardViewCount, behrPromotionCardViewCount
    const getMemberConfigPromise = dispatch(getMemberConfig()).then(unwrapResult);
    const getFollowTopicsPromise = dispatch(getFollowTopics()).then(unwrapResult);

    Promise.all([getMemberConfigPromise, getFollowTopicsPromise])
      .then((responses) => {
        // responses is an array of the results from all the thunks
        const memberConfig = responses[0];
        const followTopics = responses[1];

        // logic on whether to show the followTopics card
        if (!(memberConfig?.showFollowTopicsCard)) {
          setShowFollowTopicsCard(false);
        } else if (followTopics.length > 0 ||
          followTopicsCardViewCount >= maxFollowTopicsCardViewCount) {
          hideFollowTopicsCard();
        } else {
          setShowFollowTopicsCard(true);
        }

        // logic on whether to show the behr card
        if (!(memberConfig?.showBehrPromotion)) {
          setShowBehrPromotionCard(false);
        } else if (behrPromotionCardViewCount >= maxBehrPromotionCardViewCount) {
          hideBehrPromotionCard();
        } else {
          setShowBehrPromotionCard(true);
        }
      })
      .catch((error) => {
        console.error('One of the requests failed:', error);
      });

    if (!followTopicsCardViewed) { // one view per refresh
      console.log(`Follow topics card view count: ${followTopicsCardViewCount}`);
      setFollowTopicsCardViewed(true);
      dispatch(UpdateFollowTopicsCardViewCount(
        followTopicsCardViewCount + 1,
      )); // increment view count
    }

    if (!behrPromotionCardViewed) { // one view per refresh
      console.log(`Behr prmotion card view count: ${behrPromotionCardViewCount}`);
      setBehrPromotionCardViewed(true);
      dispatch(UpdateBehrPromotionCardViewCount(
        behrPromotionCardViewCount + 1,
      )); // increment view count
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFollowTopicsCardClose = async () => {
    hideFollowTopicsCard();
  };

  const onBehrPromotionCardClose = async () => {
    hideBehrPromotionCard();
  };

  const onBehrPromotionCardClickButton = async () => {
    const url = 'https://www.behr.com/pro/contact-a-rep';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const BehrAndKilzImage: React.FC = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'row',
        },
      }}
    >
      <BehrLogo />
      <AddIcon />
      <KilzLogo />
    </Box>
  );

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 4, md: 4 }}
    >
      {/* TODO: enable this after trending topics is added */}
      {/*
            {location.state?.fromOnboarding && <Grid item xs={4} sm={4} md={4}>
              <Box
                sx={{
                  background:
                  'radial-gradient(104.99% 53.33% at 50% 46.67%, #78B0C5 0%, #569BB4 100%)',
                  borderRadius: '26px',
                  paddingLeft: '24px',
                  paddingTop: '56px',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/rewards');
                }}
              >
                <ServiceFusionLogo />
                <Box sx={{
                  fontWeight: '600',
                  color: '#FFFFFF',
                  letterSpacing: '0.335977px',
                  paddingRight: '20px',
                  fontSize: '30px',
                  marginTop: '18px',
                  lineHeight: '37px',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '20px',
                    lineHeight: '27px',
                    paddingRight: '0px',
                    letterSpacing: '0px',
                  },
                }}>
                  {'Welcome to Service Nation Edge where you earn and learn'}
                </Box>
                <Box sx={{
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '24px',
                  letterSpacing: '0.0898333px',
                  color: '#FFFFFF',
                  marginTop: '8px',
                  paddingRight: '20px',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '16px',
                    paddingRight: '0px',
                  },
                }}>
                  {'Watch your rebates grow,
                  receive quarterly payments, and receive industry news.'}
                </Box>
                <Box sx={{
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '12px',
                  letterSpacing: '-0.287467px',
                  color: '#FFFFFF',
                  marginTop: '24px',
                  paddingRight: '20px',
                  marginBottom: '36px',
                }}>
                  {'Exclusions on items may apply.'}
                </Box>
              </Box>
            </Grid>}
            */}
      {!isDesktopScreen &&
        <Grid item xs={4} sm={4} md={4} sx={{ paddingTop: '0px !important' }}>
          <HDSetupFlowCard hideDoneState={true} />
        </Grid>
          }
      {/* verificationEmailFlag &&
        <Grid item xs={4} sm={4} md={4} sx={{ paddingTop: '0px !important' }}>
          <PaypalVerificationCard
            optionalCardStyle={optionalCardStyle}
            optionalBtnStyles={{
              background: theme.palette.secondary.main,
              borderRadius: '30px',
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
              '&:disabled': {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
            emailInputStyles={{
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: theme.palette.secondary.main,
                },
              },
            }}
          />
          </Grid> */}
      {/* <Grid item xs={4} sm={4} md={4} sx={{ paddingTop: '0px !important' }}>
        <LennoxAccountNumberCard
          optionalCardStyle={optionalCardStyle}
          optionalBtnStyles={{
            background: theme.palette.secondary.main,
            borderRadius: '30px',
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          inputStyles={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: theme.palette.secondary.main,
              },
            },
          }}
        />
        </Grid> */}
        {showFollowTopicsCard ? // Removed always show the topic card
          <Grid item key={'follow-topics-dashboard'}
            sx={{
              paddingTop: theme.spacing(3),
              paddingLeft: theme.spacing(3),
              [theme.breakpoints.down('md')]: {
                paddingTop: theme.spacing(2),
                paddingLeft: theme.spacing(2),
              },
            }}
          >
            <Box id={'optimus-content-follow-topics-dashboard'}>
              <FollowTopicsCard
                isHomeCard={true}
                onCardClose={onFollowTopicsCardClose} />
            </Box>
          </Grid> : null
        }
        {discountFlag && !showFollowTopicsCard && showBehrPromotionCard &&
          <Grid
            sx={{
              paddingTop: theme.spacing(3),
              paddingLeft: theme.spacing(3),
              width: '100%',
              [theme.breakpoints.down('md')]: {
                paddingTop: theme.spacing(2),
                paddingLeft: theme.spacing(2),
              },
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <PromotionCard
                promotionText={t('partners.promotionEnrolled')}
                promotionButtonText={t('button.learnMore')}
                promotionImage={BehrAndKilzImage}
                onCardClose={onBehrPromotionCardClose}
                onCardClickButton={onBehrPromotionCardClickButton}
              />
            </Box>
          </Grid>
        }
      <ContentHome contentViewBaseUrl={'/home'} />
    </Grid>
  );
}
