import { useEffect, useState } from 'react';
import {
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ReactComponent as BehrLogo } from '../../assets/images/behr_logo.svg';
import { ReactComponent as KilzLogo } from '../../assets/images/kilz_logo.svg';
import { ReactComponent as HDLogo } from '../../assets/images/hd_logo.svg';
import { selectGetHomeDepotMemberRelationState, selectHomeDepotMemberRelation } from '../../store/slices/dashboardSlice';
import { HDVerificationStatus } from '../../common/enums';
import { PartnerCardSkeleton } from '../../common/PartnerEnrollmentCardSkeleton';
import { PartnerCard } from '../../common/PartnerEnrollmentCardV2';
import { getHomeDepotMemberRelation } from '../../store/thunks/dashboardThunk';

export function Partners() {
  const dispatch = useAppDispatch();
  const hdMemberRelation = useAppSelector(selectHomeDepotMemberRelation);
  const hdMemberRelationState = useAppSelector(selectGetHomeDepotMemberRelationState);
  const [verificationStatus, setVerificationStatus] = useState(HDVerificationStatus.Pending);
  const { t } = useTranslation();

  useEffect(() => {
    if (!hdMemberRelation) {
      dispatch(getHomeDepotMemberRelation());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hdMemberRelation?.partnerMemberKey || hdMemberRelation?.partnerMemberEmail) {
      setVerificationStatus(HDVerificationStatus.Completed);
    } else {
      setVerificationStatus(HDVerificationStatus.Pending);
    }
  }, [hdMemberRelation]);

  const partnersData = [
    {
      name: t('homeDepot.title'),
      logo: HDLogo,
      description: t('homeDepot.partnerDescription'),
      perks: [`${t('partners.perk1')}`],
      links: [
        { text: t('homeDepot.link1'), url: 'https://www.homedepot.com/l/store-locator' },
        { text: t('homeDepot.link2'), url: 'https://www.homedepot.com/c/mobile-app' },
        { text: t('homeDepot.link3'), url: 'https://www.homedepot.com/SpecialBuy/ProSpecialBuyOfTheWeek' },
      ],
    },
    {
      name: t('behr.title'),
      logo: BehrLogo,
      description: t('behr.partnerDescription'),
      perks: [`${t('partners.perk1')}`, `${t('partners.perk2')}`, `${t('partners.perk3')}`],
      links: [
        { text: t('behr.link1'), url: 'https://www.behr.com/pro/contact-a-rep' },
        { text: t('behr.link2'), url: 'https://www.behr.com/pro/pro-hub-stores/?location=null&location%25252F=' },
      ],
    },
    {
      name: t('kilz.title'),
      logo: KilzLogo,
      description: t('kilz.partnerDescription'),
      perks: [`${t('partners.perk1')}`, `${t('partners.perk2')}`, `${t('partners.perk3')}`],
      links: [
        { text: t('kilz.link1'), url: 'https://www.kilz.com/blog/inspiration/' },
        { text: t('kilz.link2'), url: 'https://www.kilz.com/calculator' },
      ],
    },
  ];

  return (
    <Box>
      {
        (hdMemberRelationState === 'pending') ?
        <Box>
           <PartnerCardSkeleton numberOfSkeletons={3} />
        </Box> :
        <Box>
          {partnersData.map((partner, index) => (
            <PartnerCard
              key={index}
              PartnerLogo={partner.logo}
              partnerInfo={partner}
              enrolled={verificationStatus}
            />
          ))}
        </Box>
      }
    </Box>
  );
}
