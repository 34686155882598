import { Box } from '@mui/material';
import ReactPlayer from 'react-player';

interface VideoPlayerProps{
  videoUrl: string;
  optionalVideoContainerStyle?: {
    [k: string]: unknown;
  }
}

export function VideoPlayer({
  videoUrl,
  optionalVideoContainerStyle,
}:VideoPlayerProps) {
  return (
    <Box maxWidth='xs'
      sx={{
        ...(optionalVideoContainerStyle || {}),
      }}
    >
      <ReactPlayer
        url={videoUrl}
        controls={true}
        width="100%"
        height="100%"
        playing={false} // autoplay off
        loop={false} // repeat off
        muted={true} // sound on by default
      />
    </Box>
  );
}

export default VideoPlayer;
